import React from "react"
import Container from "../Container"
const Footer = () => {
  return (
    <footer className="footer-wrapper">
      <div className="section-bg"></div>
      <Container>
        <div className="footer-links-container">
          <ul className="footer-links-wrapper">
            <li className="footer-link">
              <a
                className="footer-link-anchor"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.telstra.com.au/business-enterprise/contact-us"
              >
                Contact Us
              </a>
            </li>
            <li className="footer-link">
              <a
                className="footer-link-anchor"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.telstra.com.au/privacy"
              >
                Privacy
              </a>
            </li>
            <li className="footer-link">
              <a
                className="footer-link-anchor"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.telstra.com.au/customer-terms"
              >
                Our Customer Terms
              </a>
            </li>
          </ul>
          <div className="footer-copy-wrapper">
            <h5 className="footer-copy-title">THINGS YOU NEED TO KNOW:</h5>
            <p className="footer-copy-content">
              nbn&#x2122;, nbn co and other nbn&#x2122; logos and brands are trade marks of nbn co
              limited and used under licence.
            </p>
          </div>
        </div>
      </Container>
    </footer>
  )
}

export default Footer

import React from "react"
import ArticleCollection from "../ArticleCollection"
import FilterContainer from "../FilterContainer"
import Layout from "../Layout"
import monthNameMapping from "../../monthNameMapping"
import SweetScroll from "sweet-scroll"

const sweetScroll = new SweetScroll({
  duration: 1200,
  easing: "easeOutExpo",
  offset: -20,
})

// This is a parent container which wraps articles and filter component
export default class ArticleFilterContainer extends React.PureComponent {
  state = {
    articleCollection: [],
    monthsCollection: [],
    searchedInput: "",
    currentIssueFilterValue: "",
    expandedArticleIdCollection: [],
  }

  setArticles = () => {
    const { articles, months, currentMonth, articleId } = this.props
    if (articles.length > 0) {
      this.setState({
        articleCollection: articles,
      })
    }
    if (months.length > 0) {
      this.setState({
        monthsCollection: months,
      })
      const year = currentMonth ? currentMonth.year : months[0].year
      const month = currentMonth ? currentMonth.month : months[0].month
      this.setCurrentArticlesMonth(year, month)
    }
    if (articleId) {
      this.setState({
        expandedArticleIdCollection: [articleId],
        currentIssueFilterValue: articleId,
      })
    }
  }

  componentDidMount = () => {
    this.setArticles()
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.articles.length !== this.props.articles.length ||
      JSON.stringify(prevProps.currentMonth) !== JSON.stringify(this.props.currentMonth)
    ) {
      this.setArticles()
    }
  }

  handleCurrentIssueFilter = (e) => {
    const articleId = e.target.value
    if (articleId !== "") {
      const element = document.getElementById(articleId)
      sweetScroll.toElement(element)
      this.props.setArticleId(articleId)
      this.setState({
        currentIssueFilterValue: articleId,
      })
    }
  }

  handleArchivedFilter = (e) => {
    if (e.target.value !== "") {
      const parseFilterValue = e.target.value.split("_")
      const selectedMonth = Number(parseFilterValue[0])
      const selectedYear = Number(parseFilterValue[1])

      this.setCurrentArticlesMonth(selectedYear, selectedMonth)
    }
  }

  setCurrentArticlesMonth = (selectedYear, selectedMonth) => {
    const filteredArchiveData = this.props.articles.filter(
      (article) => article.year === selectedYear && article.month === selectedMonth
    )
    this.setState({
      articleCollection: filteredArchiveData,
    })

    if (this.props.onSelectMonth) {
      this.props.onSelectMonth({ year: selectedYear, month: selectedMonth })
    }
  }

  onSearchInputChange = (e) => {
    this.setState({
      searchedInput: e.target.value,
    })
  }

  // Filter data based on user text input and set state of articleCollection with new data
  handleSearchFormSubmission = (e) => {
    e.preventDefault()
    const { searchedInput } = this.state
    const matchingSearchedArticles = []
    if (searchedInput !== "" || searchedInput !== undefined) {
      const searchedInputLower = searchedInput.toLowerCase()
      //check the article title, overview and content to match against searched input
      this.props.articles.forEach((article) => {
        if (
          article.title.toLowerCase().indexOf(searchedInputLower) !== -1 ||
          article.summary.toLowerCase().indexOf(searchedInputLower) !== -1 ||
          article.content.toLowerCase().indexOf(searchedInputLower) !== -1
        ) {
          matchingSearchedArticles.push(article)
        }
      })
    }
    this.setState({
      articleCollection: matchingSearchedArticles,
    })
  }

  // Generate the select options for current issue select filter based on passed data from the parent
  generateCurrentIssueFilterOptions = () => {
    const { articleCollection } = this.state
    let currentIssueOptions = []
    if (articleCollection.length > 0) {
      articleCollection.forEach((article) => {
        currentIssueOptions.push({
          label: article.title,
          value: article.id,
        })
      })
    }
    return currentIssueOptions
  }

  // Generate the select options for archived select filter based on passed data from the parent
  generateArchivedFilterOptions = () => {
    const { monthsCollection } = this.state
    const archivedMonthOptions = []
    if (monthsCollection.length > 0) {
      monthsCollection.forEach((monthItem) => {
        archivedMonthOptions.push({
          label: `${monthNameMapping[monthItem.month]} ${monthItem.year}`,
          value: `${monthItem.month}_${monthItem.year}`,
        })
      })
    }
    return archivedMonthOptions
  }

  /* Add or remove the article id from the array collection when the article is expanded or collapsed by clicking Read more or less in Article component.
  This function makes sure that only those articles shows in expanded state which was clicked by the user before applying
  any new filter */
  handleExpandedArticleIdCollection = (articleId) => {
    if (articleId) {
      let idCollection = this.state.expandedArticleIdCollection
      const ifIdExist = idCollection.includes(articleId)
      if (!ifIdExist) {
        idCollection.push(articleId)
        this.props.setArticleId(articleId)  
      } else {
        idCollection = idCollection.filter((item) => item !== articleId)
      }
      this.setState({
        expandedArticleIdCollection: idCollection,
      })
    }
  }

  render() {
    const {
      searchedInput,
      articleCollection,
      currentIssueFilterValue,
      expandedArticleIdCollection,
    } = this.state
    return (
      <Layout>
        <div className="article-filter-container-wrapper">
          <div className="filter-container-wrapper">
            <FilterContainer
              handleCurrentIssueFilter={this.handleCurrentIssueFilter}
              currentIssueFilterOptions={this.generateCurrentIssueFilterOptions()}
              handleArchivedFilter={this.handleArchivedFilter}
              archivedFilterOptions={this.generateArchivedFilterOptions()}
              onSearchInputChange={this.onSearchInputChange}
              searchedInput={searchedInput}
              handleSearchFormSubmission={this.handleSearchFormSubmission}
              isAllResultsLoaded={this.props.isAllResultsLoaded}
              currentMonth={this.props.currentMonth}
              articleId={this.props.articleId}
            />
          </div>
          <div className="articles-container-wrapper">
            <ArticleCollection
              currentIssueFilterValue={currentIssueFilterValue}
              articleCollection={articleCollection}
              handleExpandedArticleIdCollection={this.handleExpandedArticleIdCollection}
              expandedArticleIdCollection={expandedArticleIdCollection}
              articleId={this.props.articleId}
            />
          </div>
        </div>
      </Layout>
    )
  }
}

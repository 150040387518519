import React, { useState, useEffect } from "react"
import Button from "../Button"
import { IconArrowRight } from "../Icon"
import placeholderImage from "../../images/article-placeholder.jpg"
import monthMapping from "../../monthNameMapping"

const Article = (props) => {
  const [isExpanded, setExpanded] = useState(false)

  const getButtonLabel = () => (isExpanded ? "Read less" : "Read more")
  const {
    article,
    currentIssueFilterValue,
    handleExpandedArticleIdCollection,
    expandedArticleIdCollection,
  } = props

  /* If the article id matches with the selected article from the drop down
  then expand the article */
  useEffect(() => {
    if (currentIssueFilterValue === article.id) {
      setExpanded(true)
    }
  }, [currentIssueFilterValue, article.id, handleExpandedArticleIdCollection])

  useEffect(() => {
    if (typeof props.expanded !== "undefined") {
      setExpanded(props.expanded)
    }
  }, [props.expanded])

  /*This function makes sure that only those articles shows in expanded state which was clicked by the user before applying
  any new filter*/
  useEffect(() => {
    if (expandedArticleIdCollection.includes(article.id)) {
      setExpanded(true)
    } else {
      setExpanded(false)
    }
  }, [expandedArticleIdCollection, article.id])

  const onArticleReadBtnClick = (articleId) => {
    setExpanded(!isExpanded)
    handleExpandedArticleIdCollection(articleId)
  }

  // Switch the article content based on button expanded status
  const switchContent = () =>
    isExpanded ? (
      <div dangerouslySetInnerHTML={{ __html: article.content }} />
    ) : (
      <div dangerouslySetInnerHTML={{ __html: article.summary }} />
    )

  return (
    <div className="article-wrapper">
      <div className="article-image">
        <img src={article.image || placeholderImage} alt="article" />
      </div>
      <div className="article-content-wrapper">
        <div className="article-title-wrapper theme-blue-copy">
          <h2
            className="article-title"
            dangerouslySetInnerHTML={{ __html: `<a href=#${article.id}>${article.title}</a>` }}
          ></h2>
        </div>
        <div className="published-info">
          {article.status === "Published" && article.month && article.year && (
            <p>
              Published {monthMapping[article.month]} {article.year}
            </p>
          )}
        </div>
        <div className="article-content">{switchContent()}</div>
        <div className="btn-wrapper" onClick={() => onArticleReadBtnClick(article.id)}>
          <Button btnState={isExpanded ? "inactive" : "active"}>
            {getButtonLabel()}
            <IconArrowRight fill={isExpanded ? "#0d54ff" : "#fff"} />
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Article

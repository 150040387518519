import React from "react"

const SVG = ({
  style = {},
  width = "32",
  height = "32",
  className = "",
  viewBox = "0 0 32 32",
  children,
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    {children}
  </svg>
)

export const IconChevronUpSmall = (props) => (
  <SVG viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="m16 15c-.3 0-.5-.1-.7-.3l-3.3-3.3-3.3 3.3c-.4.4-1 .4-1.4 0s-.4-1 0-1.4l4-4c.4-.4 1-.4 1.4 0l4 4c.4.4.4 1 0 1.4-.2.2-.4.3-.7.3"
    />
  </SVG>
)

export const IconChevronDownSmall = (props) => (
  <SVG viewBox="0 0 30 30" {...props}>
    <g
      id="Wholesale-Weekly"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g
        id="WebPage_tablet"
        transform="translate(-1423.000000, -2528.000000)"
        stroke={props.stroke ? props.stroke : "#FFFFFF"}
        strokeWidth="2.5"
      >
        <g id="More-Info" transform="translate(1120.000000, 1915.000000)">
          <g id="CTA-Copy-4" transform="translate(56.000000, 591.000000)">
            <polyline id="Path-2" points="249 24 268 38.4283539 249 52"></polyline>
          </g>
        </g>
      </g>
    </g>
  </SVG>
)

export const IconSelectDown = (props) => (
  <SVG width="16" height="9" viewBox="0 0 16 9" {...props}>
    <path
      d="M14.8 1.20001L8.00003 7.99997L1.20003 1.19998"
      stroke="black"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SVG>
)

export const IconArrowRight = (props) => (
  <SVG viewBox="0 0 15 14" width="15" height="14" {...props}>
    <path
      d="M14.4647 7.02801C14.6188 7.17307 14.6959 7.36347 14.6959 7.59921C14.6959 7.82587 14.6188 8.01627 14.4647 8.17041L9.66385 12.9712C9.50972 13.1253 9.32385 13.2024 9.10625 13.2024C8.87959 13.2024 8.68919 13.1253 8.53505 12.9712C8.38092 12.8171 8.30385 12.6267 8.30385 12.4C8.30385 12.1733 8.38092 11.9829 8.53505 11.8288L11.9623 8.40161H1.09585C0.878254 8.40161 0.692387 8.32454 0.538254 8.17041C0.38412 8.01627 0.307054 7.82587 0.307054 7.59921C0.307054 7.38161 0.38412 7.19574 0.538254 7.04161C0.692387 6.87841 0.878254 6.79681 1.09585 6.79681H11.9623L8.53505 3.36961C8.38092 3.19734 8.30385 3.00694 8.30385 2.79841C8.30385 2.58081 8.38092 2.39494 8.53505 2.24081C8.68919 2.08667 8.87959 2.00961 9.10625 2.00961C9.33292 2.00961 9.51879 2.08667 9.66385 2.24081L14.4647 7.02801Z"
      fill={props.fill || "white"}
    />
  </SVG>
)

export const IconClose = (props) => (
  <SVG {...props}>
    <path
      fill="currentColor"
      d="m17.4 16 6.3-6.3c.2-.2.3-.4.3-.7s-.1-.5-.3-.7c-.4-.4-1-.4-1.4 0l-6.3 6.3-6.3-6.3c-.4-.4-1-.4-1.4 0-.2.2-.3.4-.3.7s.1.5.3.7l6.3 6.3-6.3 6.3c-.2.2-.3.4-.3.7s.1.5.3.7.4.3.7.3.5-.1.7-.3l6.3-6.3 6.3 6.3c.2.2.5.3.7.3.3 0 .5-.1.7-.3s.3-.5.3-.7-.1-.5-.3-.7z"
    />
  </SVG>
)

export const IconSearch = (props) => (
  <SVG {...props}>
    <path
      fill="currentColor"
      d="m7 14c0-3.9 3.1-7 7-7s7 3.1 7 7-3.1 7-7 7-7-3.1-7-7m18.4 11.3-5-5c1.6-1.6 2.6-3.8 2.6-6.3 0-5-4-9-9-9s-9 4-9 9 4 9 9 9c1.8 0 3.4-.5 4.8-1.4l5.1 5.1c.2.2.5.3.7.3s.5-.1.7-.3c.5-.4.5-1 .1-1.4"
    />
  </SVG>
)

export const IconFilter = (props) => (
  <SVG viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M5,7A1,1,0,0,1,6,6H7.28a2,2,0,0,1,3.44,0H18a1,1,0,0,1,0,2H10.72A2,2,0,0,1,7.28,8H6A1,1,0,0,1,5,7m12.75,4h-1a2,2,0,0,0-3.44,0H6a1,1,0,0,0,0,2h7.28a2,2,0,0,0,3.44,0H18a1,1,0,0,0,0-2m-.25,5h-7a2,2,0,0,0-3.44,0H6a1,1,0,0,0,0,2H7.28a2,2,0,0,0,3.44,0H18a1,1,0,0,0,0-2"
    />
  </SVG>
)

export const IconLogo = (props) => (
  <SVG width="134" height="38" viewBox="0 0 134 38" {...props}>
    <g clipPath="url(#clip0_501_659)">
      <path
        shapeRendering="optimizeQuality"
        d="M22.1064 16.9826L20.6837 25.337C20.3914 26.8409 19.4007 27.247 18.5204 27.247H11.9363L14.7102 11.9479C11.9395 10.6811 9.11031 9.85278 6.8593 9.85278C4.60829 9.85278 2.97443 10.4342 1.81807 11.8212C1.00277 12.8087 0.596741 14.0267 0.596741 15.4754C0.596741 19.8248 4.01711 25.86 9.87364 30.7843C15.0935 35.1369 20.8364 37.573 25.0168 37.573C27.1022 37.573 28.7847 36.9331 29.8859 35.6598C30.7564 34.6724 31.1007 33.3926 31.1007 31.9471C31.1007 27.7212 27.6544 21.7965 22.1032 16.9859L22.1064 16.9826Z"
        fill="#F96449"
      />
      <path
        shapeRendering="optimizeQuality"
        d="M10.2862 0.799988C9.24025 0.799988 8.37298 1.5016 8.14235 2.60275L7.21661 7.64398H15.4963L11.9395 27.2437H18.5236C19.4007 27.2437 20.3914 26.8377 20.687 25.3338L23.6981 7.64398H29.6553C30.7045 7.64398 31.5717 6.94886 31.8023 5.84771L32.7313 0.799988H10.2862Z"
        fill="#0D54FF"
      />
      <path
        shapeRendering="optimizeQuality"
        d="M60.2469 25.4475H58.1518L55.3323 16.2063L52.5324 25.4475H50.3983L46.455 12.4904H48.8002L51.5644 21.8842L54.3838 12.4904H56.2516L59.11 21.9784L61.8742 12.4904H64.2L60.2566 25.4475H60.2469Z"
        fill="#1A2E61"
      />
      <path
        shapeRendering="optimizeQuality"
        d="M64.9341 12.4903H67.1071V17.0053C67.4254 16.5993 67.8282 16.2777 68.3187 16.0439C68.8059 15.81 69.3451 15.6898 69.9298 15.6898C70.8815 15.6898 71.6773 16.0114 72.3107 16.6513C72.9474 17.2944 73.2625 18.2429 73.2625 19.5V25.4442H71.1089V19.5747C71.1089 18.951 70.9368 18.4768 70.5957 18.1455C70.2514 17.8141 69.8031 17.6485 69.2412 17.6485C68.6045 17.6485 68.0913 17.8499 67.6983 18.2494C67.3053 18.6489 67.1071 19.1557 67.1071 19.7631V25.441H64.9341V12.4838V12.4903Z"
        fill="#1A2E61"
      />
      <path
        shapeRendering="optimizeQuality"
        d="M79.7427 25.6781C79.0313 25.6781 78.3752 25.5514 77.771 25.298C77.1668 25.0447 76.6471 24.6971 76.2086 24.2586C75.7701 23.8201 75.4258 23.2939 75.1789 22.6767C74.9321 22.0596 74.8086 21.3969 74.8086 20.6856C74.8086 19.9742 74.9321 19.3116 75.1789 18.6944C75.4258 18.0772 75.7701 17.551 76.2086 17.1125C76.6471 16.674 77.1668 16.3265 77.771 16.0731C78.3752 15.8197 79.0313 15.6931 79.7427 15.6931C80.454 15.6931 81.1102 15.8197 81.7143 16.0731C82.3185 16.3265 82.8382 16.674 83.2767 17.1125C83.7152 17.551 84.0595 18.0772 84.3064 18.6944C84.5533 19.3116 84.6767 19.9742 84.6767 20.6856C84.6767 21.3969 84.5533 22.0596 84.3064 22.6767C84.0595 23.2939 83.7152 23.8201 83.2767 24.2586C82.8382 24.6971 82.3185 25.0447 81.7143 25.298C81.1102 25.5514 80.454 25.6781 79.7427 25.6781ZM79.7427 17.5803C78.9436 17.5803 78.2842 17.8596 77.771 18.4183C77.2578 18.977 76.9979 19.7338 76.9979 20.6856C76.9979 21.6373 77.2545 22.3941 77.771 22.9528C78.2875 23.5115 78.9436 23.7909 79.7427 23.7909C80.5417 23.7909 81.2044 23.5115 81.7241 22.9528C82.2438 22.3941 82.5069 21.6373 82.5069 20.6856C82.5069 19.7338 82.247 18.977 81.7241 18.4183C81.2044 17.8596 80.5417 17.5803 79.7427 17.5803Z"
        fill="#1A2E61"
      />
      <path
        shapeRendering="optimizeQuality"
        d="M86.2781 12.4904H88.4511V25.4475H86.2781V12.4904Z"
        fill="#1A2E61"
      />
      <path
        shapeRendering="optimizeQuality"
        d="M99.4821 21.3709H92.2223C92.349 22.183 92.6641 22.8099 93.1643 23.2484C93.6645 23.6869 94.2914 23.9045 95.0418 23.9045C95.6264 23.9045 96.1104 23.7843 96.5002 23.544C96.8867 23.3036 97.1401 23.0113 97.2538 22.667H99.4074C99.128 23.557 98.5953 24.2781 97.806 24.8303C97.0167 25.3825 96.0714 25.6586 94.967 25.6586C94.2297 25.6586 93.5573 25.5351 92.9467 25.2883C92.336 25.0414 91.8163 24.6971 91.3843 24.2586C90.9523 23.8201 90.6144 23.2939 90.3741 22.6767C90.1337 22.0595 90.0135 21.3969 90.0135 20.6855C90.0135 19.9742 90.1337 19.3115 90.3741 18.6944C90.6144 18.0772 90.9523 17.551 91.3843 17.1125C91.8163 16.674 92.3295 16.3297 92.9272 16.0828C93.5248 15.836 94.1777 15.7125 94.8891 15.7125C95.6005 15.7125 96.2241 15.836 96.7958 16.0828C97.3675 16.3297 97.8515 16.674 98.2445 17.1125C98.6375 17.551 98.9429 18.0707 99.1605 18.6749C99.3749 19.2791 99.4853 19.9287 99.4853 20.6271V21.3709H99.4821ZM94.8891 17.4828C94.2654 17.4828 93.7132 17.6777 93.2325 18.0642C92.7485 18.4508 92.4334 19.0127 92.2808 19.7501H97.3123C97.2603 19.1654 97.0362 18.6424 96.6366 18.1779C96.2371 17.7134 95.6557 17.4828 94.8923 17.4828H94.8891Z"
        fill="#1A2E61"
      />
      <path
        shapeRendering="optimizeQuality"
        d="M102.626 22.589C102.652 22.9951 102.834 23.3361 103.169 23.609C103.507 23.8818 104.004 24.0182 104.663 24.0182C105.209 24.0182 105.667 23.9143 106.034 23.7032C106.401 23.4953 106.586 23.1867 106.586 22.7807C106.586 22.5013 106.488 22.2739 106.29 22.0953C106.092 21.9166 105.761 21.7965 105.29 21.7347L103.442 21.4294C102.49 21.2767 101.775 20.9747 101.298 20.5232C100.82 20.0717 100.583 19.4772 100.583 18.7399C100.583 18.2429 100.687 17.8044 100.898 17.4244C101.109 17.0443 101.392 16.726 101.746 16.4726C102.1 16.2193 102.522 16.0244 103.003 15.8912C103.487 15.758 104 15.6898 104.546 15.6898C105.03 15.6898 105.507 15.7483 105.985 15.862C106.462 15.9757 106.885 16.1511 107.252 16.3849C107.619 16.6188 107.924 16.9209 108.168 17.2912C108.408 17.6582 108.548 18.0902 108.587 18.5872H106.32C106.281 18.2202 106.108 17.9213 105.806 17.6907C105.501 17.4633 105.075 17.3464 104.53 17.3464C103.984 17.3464 103.562 17.4504 103.224 17.6615C102.886 17.8726 102.721 18.1552 102.721 18.5093C102.721 18.8016 102.828 19.0322 103.045 19.2044C103.26 19.3765 103.611 19.5 104.094 19.5747L105.943 19.88C106.807 20.0197 107.492 20.299 108.002 20.7181C108.509 21.1371 108.765 21.7607 108.765 22.5858C108.765 23.1055 108.658 23.5602 108.441 23.9468C108.223 24.3333 107.934 24.6549 107.564 24.9083C107.197 25.1616 106.758 25.3533 106.248 25.4799C105.741 25.6066 105.199 25.6716 104.627 25.6716C103.344 25.6716 102.311 25.4052 101.532 24.8725C100.752 24.3398 100.353 23.5765 100.34 22.5858H102.626V22.589Z"
        fill="#1A2E61"
      />
      <path
        shapeRendering="optimizeQuality"
        d="M115.944 25.4475V24.2846C115.626 24.7166 115.2 25.0577 114.667 25.3045C114.135 25.5514 113.544 25.6748 112.894 25.6748C111.929 25.6748 111.156 25.415 110.578 24.892C110 24.3723 109.711 23.6609 109.711 22.7579C109.711 21.8549 110.016 21.1663 110.627 20.7278C111.237 20.2893 112.01 19.9937 112.952 19.841L115.886 19.4025V18.7724C115.886 18.3014 115.749 17.9441 115.476 17.6972C115.203 17.4504 114.742 17.3269 114.096 17.3269C113.485 17.3269 113.027 17.4406 112.715 17.6712C112.403 17.8986 112.241 18.2234 112.228 18.6424H110.019C110.032 18.2624 110.107 17.8889 110.247 17.5283C110.386 17.1678 110.614 16.8527 110.932 16.5863C111.25 16.32 111.669 16.1023 112.189 15.9367C112.709 15.771 113.352 15.6898 114.112 15.6898C115.496 15.6898 116.499 15.9497 117.123 16.4726C117.747 16.9924 118.055 17.7427 118.055 18.7204V25.4475H115.941H115.944ZM111.884 22.7027C111.884 23.0957 112.014 23.4108 112.274 23.6447C112.533 23.8786 112.917 23.9988 113.427 23.9988C114.125 23.9988 114.71 23.7876 115.181 23.3686C115.652 22.9496 115.886 22.4494 115.886 21.8647V20.9487L113.446 21.3482C112.975 21.4229 112.598 21.5658 112.313 21.7672C112.027 21.9719 111.884 22.2804 111.884 22.7027Z"
        fill="#1A2E61"
      />
      <path
        shapeRendering="optimizeQuality"
        d="M120.196 12.4904H122.369V25.4475H120.196V12.4904Z"
        fill="#1A2E61"
      />
      <path
        shapeRendering="optimizeQuality"
        d="M133.4 21.3709H126.14C126.267 22.183 126.582 22.8099 127.082 23.2484C127.582 23.6869 128.209 23.9045 128.96 23.9045C129.544 23.9045 130.028 23.7843 130.418 23.544C130.805 23.3036 131.058 23.0113 131.172 22.667H133.325C133.046 23.557 132.513 24.2781 131.724 24.8303C130.935 25.3825 129.989 25.6586 128.885 25.6586C128.148 25.6586 127.475 25.5351 126.865 25.2883C126.254 25.0414 125.734 24.6971 125.302 24.2586C124.87 23.8201 124.532 23.2939 124.292 22.6767C124.052 22.0595 123.931 21.3969 123.931 20.6855C123.931 19.9742 124.052 19.3115 124.292 18.6944C124.532 18.0772 124.87 17.551 125.302 17.1125C125.734 16.674 126.247 16.3297 126.845 16.0828C127.443 15.836 128.096 15.7125 128.807 15.7125C129.518 15.7125 130.142 15.836 130.714 16.0828C131.285 16.3297 131.769 16.674 132.162 17.1125C132.555 17.551 132.861 18.0707 133.078 18.6749C133.293 19.2791 133.403 19.9287 133.403 20.6271V21.3709H133.4ZM128.807 17.4828C128.183 17.4828 127.631 17.6777 127.15 18.0642C126.666 18.4508 126.351 19.0127 126.199 19.7501H131.23C131.178 19.1654 130.954 18.6424 130.555 18.1779C130.155 17.7134 129.574 17.4828 128.81 17.4828H128.807Z"
        fill="#1A2E61"
      />
    </g>
    <defs>
      <clipPath id="clip0_501_659">
        <rect
          shapeRendering="optimizeQuality"
          width="132.8"
          height="36.773"
          fill="white"
          transform="translate(0.600006 0.799988)"
        />
      </clipPath>
    </defs>
  </SVG>
)

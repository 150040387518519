import React from "react"

const Container = ({ bgColor, children }) => {
  return (
    <div className="content-container-wrapper" style={{ background: bgColor }}>
      <div className="content-container">{children}</div>
    </div>
  )
}

export default Container

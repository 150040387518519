import React from "react"

const Button = (props) => {
  const { href, children, btnState } = props
  return href ? (
    <a className={`cta-button _${btnState}`} href={href} rel="noopener noreferrer" target="_blank">
      {children}
    </a>
  ) : (
    <button className={`cta-button _${btnState}`}>{children}</button>
  )
}

export default Button

// The articles will be populated by Eloqua using
// dynamic content blocks under the .elq-articles node (hidden).
// We then grab the article ID's from the nodes and
// send them to our API endpoint which will retrieve the article content

/* global SERVERLESS_API_URL */

const getArticlesIdsFromDocument = () => {
  const articlesNodesWithAttr = document.querySelectorAll(".elq-articles *[data-id]")
  const articlesNodesWithContent = document.querySelectorAll(".elq-articles .elq-article")

  return [
    ...Array.from(articlesNodesWithAttr).map(i => i.getAttribute("data-id")),
    ...Array.from(articlesNodesWithContent).map(i => i.textContent.trim())
  ]
}

export const loadArticles = async () => {
  const ids = getArticlesIdsFromDocument()
  console.log(`Found ${ids.length} articles ids injected by Eloqua...`)

  if (ids.length === 0) {
    console.log(`No articles to display...`)
    return { articles: [], months: [] }
  }

  console.log(`Loading articles...`)
  const response = await fetch(`${SERVERLESS_API_URL}/articles`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      ids: ids
    })
  })

  const result = await response.json()

  console.log(`Loaded ${result.articles.length} from API...`)

  return result
}

const fetchHistoricalDocument = async () => {
  const historicalDoc = await fetch("https://connect.telstrawholesale.com/weeklyUpdate-historical")
  const result = await historicalDoc.text()
  const parser = new DOMParser()
  const doc = parser.parseFromString(result, "text/html")
  const articles = doc.querySelectorAll(".elq-articles .elq-article")
  const ids = Array.from(articles).map(i => i.innerHTML.trim())
  return ids
}

export const loadAllArticles = async () => {
  const oldArticleDocumentIds = await fetchHistoricalDocument()

  if (oldArticleDocumentIds.length === 0) {
    console.log(`No articles to display...`)
    return { articles: [], months: [] }
  }

  console.log(`All articles call - Loading articles...`)
  const response = await fetch(`${SERVERLESS_API_URL}/articles`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      ids: oldArticleDocumentIds
    })
  })

  const result = await response.json()

  console.log(`All articles - Loaded ${result.articles.length} from API...`)

  return result
}

import React, { useEffect, useState } from "react"
import Article from "../Article"

// This component loops through the collection nodes and render the Articles
const ArticleCollection = props => {
  const {
    articleCollection,
    currentIssueFilterValue,
    handleExpandedArticleIdCollection,
    expandedArticleIdCollection,
    articleId
  } = props
  const [expandedId, setExpandedId] = useState(null)

  useEffect(() => {
    if (articleCollection.length === 0) {
      return
    }

    const anchoredArticle = articleId && articleCollection.find(i => i.id === articleId)

    if (anchoredArticle) {
      setExpandedId(anchoredArticle.id)
      const articleAnchorEl = document.getElementById(anchoredArticle.id)
      if (articleAnchorEl) {
        setTimeout(() => {
          articleAnchorEl.scrollIntoView({ behaviour: "auto" })
        }, 500)
      }
    }
  }, [articleCollection, articleId])

  return articleCollection && articleCollection.length > 0
    ? articleCollection.map((article, index) => (
        <div className="article-collection-wrapper" key={index}>
          {/* eslint-disable-next-line */}
          <a className="article-anchor" id={article.id}></a>
          <Article
            article={article}
            currentIssueFilterValue={currentIssueFilterValue}
            expanded={article.id === expandedId}
            handleExpandedArticleIdCollection={handleExpandedArticleIdCollection}
            expandedArticleIdCollection={expandedArticleIdCollection}
          />
          {index < articleCollection.length - 1 && <div className="article-divider"></div>}
        </div>
      ))
    : null
}

export default ArticleCollection

import React from "react"
import Container from "../Container"
import { IconLogo } from "../Icon"

const Header = () => {
  return (
    <header className="header-wrapper">
      <div className="section-bg"></div>
      <Container>
        <div className="header-logo-holder">
          <a href="https://www.telstrawholesale.com.au/">
            <IconLogo />
          </a>
        </div>
      </Container>
    </header>
  )
}
export default Header

import React, { useState } from "react"
import Button from "../Button"
import { IconArrowRight, IconChevronDownSmall } from "../Icon"

const NeedMoreInfo = () => {
  const [isExpanded, setExpanded] = useState(false)

  const getClassName = () => (isExpanded ? "expanded" : "collapsed")
  const COLOR_THEME_BLUE = "#001e82"

  return (
    <div className={`needMoreInfo-wrapper ${getClassName()}`}>
      <div className="needMoreInfo-header" onClick={() => setExpanded(!isExpanded)}>
        <h3 className={`title`}>
          Need more information?
          <span className="needMoreInfo-icon-chevron">
            <IconChevronDownSmall stroke={COLOR_THEME_BLUE} width={12} height={12} />
          </span>
        </h3>
      </div>

      <div className="needMoreInfo-content">
        <div className="block">
          <p>Telstra Wholesale customers: Contact your Service Manager: Anthony Churchward</p>
          <Button
            btnState={"active"}
            href="mailto:Anthony.L.Churchward@telstrawholesale.com"
          >
            Contact me <IconArrowRight />
          </Button>
        </div>
        <div className="block">
          <p className="bold-copy">Not a Telstra Wholesale customer?</p>
          <p>Complete the new customer enquiry form and we'll get back to you.</p>
          <Button btnState={"active"} href="https://www.telstrawholesale.com.au/contact.html">
            Enquiry form <IconArrowRight />
          </Button>
        </div>
      </div>
    </div>
  )
}

export default NeedMoreInfo

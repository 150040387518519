import React, { useState, useEffect } from "react"
import Header from "./components/Header"
import HeroSection from "./components/HeroSection"
import ArticleFilterContainer from "./components/ArticleFilterContainer"
import Footer from "./components/Footer"
import { loadArticles, loadAllArticles } from "./articles"
import monthNameMapping from "./monthNameMapping"
import Container from "./components/Container"

const App = () => {
  const [months, setMonths] = useState([])
  const [articles, setArticles] = useState([])
  const [currentMonth, setCurrentMonth] = useState(null)
  const [articleId, setArticleId] = useState(window.location.hash.substring(1))
  const [isAllResultsLoaded, setIsAllResultLoaded] = useState(false)

  const handleSetArticleId = (articleId) => {
    window.location.hash = articleId
    setArticleId(articleId)
  }

  useEffect(() => {
    loadArticles().then((firstResult) => {
      let found = articleId && firstResult.articles.filter((x) => x.id === articleId)[0]
      setMonths(firstResult.months)
      setArticles(firstResult.articles)
      setCurrentMonth(found ? { year: found.year, month: found.month } : firstResult.months[0])

      // Initiate second call
      loadAllArticles().then((secondResult) => {
        setMonths(firstResult.months.concat(secondResult.months))
        setArticles(firstResult.articles.concat(secondResult.articles))
        setIsAllResultLoaded(true)
        if (!found && articleId) {
          found = secondResult.articles.filter((x) => x.id === articleId)[0]
          setCurrentMonth(found ? { year: found.year, month: found.month } : firstResult.months[0])
        }
      }).catch((err)=>{console.log(err)})
    })

    window.addEventListener('hashchange', ()=>{
      setArticleId(window.location.hash.substring(1))
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="landing-page">
      <Header />
      <HeroSection
        heroSectionDate={
          currentMonth
            ? `${monthNameMapping[currentMonth.month]} ${currentMonth.year}`
            : "Loading..."
        }
      />
      <Container>
        {articles.length > 0 ? (
          <ArticleFilterContainer
            articles={articles}
            months={months}
            onSelectMonth={setCurrentMonth}
            isAllResultsLoaded={isAllResultsLoaded}
            currentMonth={currentMonth}
            articleId={articleId}
            setArticleId={handleSetArticleId}
          />
        ) : (
          <div className="layout-container">
            <p>Loading articles...</p>
          </div>
        )}
      </Container>
      <Footer />
    </div>
  )
}

export default App

import React from "react"
import { IconSelectDown } from "../Icon"

const InputSelect = (props) => {
  const { options, handleOnSelctChange, placeholder, value } = props
  return (
    <div className="input-select-wrapper">
      <select
        className="input-select"
        defaultValue={"DEFAULT"}
        value={value}
        onChange={(e) => handleOnSelctChange(e)}
      >
        <option value="DEFAULT" disabled dangerouslySetInnerHTML={{ __html: placeholder }}></option>
        {options &&
          options.length > 0 &&
          options.map((option, index) => (
            <option
              key={index}
              value={option.value}
              dangerouslySetInnerHTML={{ __html: option.label }}
            ></option>
          ))}
      </select>
      <span className="icon-dropdown">
        <IconSelectDown />
      </span>
    </div>
  )
}

export default InputSelect

import React from "react"
import heroImage from "../../images/hero-image.jpg"
import Container from "../Container"

const HeroSection = (props) => {
  const { heroSectionDate } = props
  return (
    <Container bgColor="#0d54ff">
      <div className="hero-section-wrapper">
        <div className="hero-section-inside">
          <div className="hero-content-wrapper">
            <div className="hero-title">
              <h1>Issue {heroSectionDate}</h1>
            </div>
            <div className="hero-content">
              <p>
                Welcome to the latest update from Telstra Wholesale. We regularly cover information
                that is relevant to you about systems, services, product launches and prices.{" "}
              </p>
              <p>
                Please note that Telstra Wholesale updates are tailored to your interests so you may
                not always receive notifications of the articles in this page.{" "}
              </p>
              <p>
                If you would like to add anyone in your organisation to this mailing list, please
                let me know.
              </p>
            </div>
          </div>
          <img className="hero-image" src={heroImage} alt="hero" />
        </div>
      </div>
    </Container>
  )
}

export default HeroSection

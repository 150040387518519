import React from "react"
import InputSelect from "../InputSelect"
import InputTextSearch from "../InputTextSearch"
import NeedMoreInfo from "../NeedMoreInfo"
import loader from "../../images/loader.gif"

// This component wraps the filters (Text Search, Select and Need more information side bar column)
const FilterContainer = (props) => {
  const {
    handleCurrentIssueFilter,
    currentIssueFilterOptions,
    handleArchivedFilter,
    archivedFilterOptions,
    onSearchInputChange,
    searchedInput,
    handleSearchFormSubmission,
    isAllResultsLoaded,
    currentMonth,
    articleId,
  } = props

  return (
    <>
      <div className="filter-container">
        <div className="filter-wrapper">
          {!isAllResultsLoaded ? (
            <img className="loading-img" src={loader} alt="loader" />
          ) : (
            <InputTextSearch
              onSearchInputChange={onSearchInputChange}
              searchedInput={searchedInput}
              handleSearchFormSubmission={handleSearchFormSubmission}
            />
          )}
        </div>
        <div className="filter-wrapper filter-current-issue">
          <h3 className="filter-title">In this issue:</h3>
          <InputSelect
            handleOnSelctChange={handleCurrentIssueFilter}
            options={currentIssueFilterOptions}
            placeholder={"Select an article"}
            value={
              currentIssueFilterOptions.some((x) => x.value === articleId) ? articleId : `DEFAULT`
            }
          />
        </div>
        <div className="filter-wrapper filter-archived-issue">
          <h3 className="filter-title">Archived Issue:</h3>
          <InputSelect
            handleOnSelctChange={handleArchivedFilter}
            options={archivedFilterOptions}
            value={currentMonth ? `${currentMonth.month}_${currentMonth.year}` : `DEFAULT`}
            placeholder={"Select issue month"}
          />
        </div>
      </div>
      <div className="needMoreInfoContainer">
        <NeedMoreInfo />
      </div>
    </>
  )
}

export default FilterContainer

import React from "react"
import { IconSearch } from "../Icon"

const InputTextSearch = props => {
  const { onSearchInputChange, searchedInput, handleSearchFormSubmission } = props
  return (
    <div className="input-text-search-wrapper">
      <form onSubmit={handleSearchFormSubmission} className="search-form">
        <input
          type="text"
          onChange={onSearchInputChange}
          value={searchedInput}
          className="search-input"
          placeholder="Search for an article"
        />
        <span className="search-icon-wrapper" onClick={handleSearchFormSubmission}>
          <IconSearch />
        </span>
      </form>
    </div>
  )
}

export default InputTextSearch
